import { useAction, useData, useDetail, useList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { DettaglioReportScheda, ReportScheda, ReportSchedaJSON } from "../types"

export function useReportSchedeList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<ReportScheda>(`${API_URL}/report-scheda`, { query: params })
}
export function useDettaglioReportSchedeList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<DettaglioReportScheda>(`${API_URL}/dettaglio-report-scheda`, { query: params })
}
export function useReportScheda(id: string) {
  return useDetail<ReportScheda>(`${API_URL}/report-scheda`, id)
}

export function useDettagliReportScheda(id: string) {
  return useData<DettaglioReportScheda[]>(`${API_URL}/report-scheda/${id}/dettagli`, { query: {} })
}

export function useReportSchedaJson(id: string) {
  return useData<ReportSchedaJSON>(`${API_URL}/report-scheda/${id}/json`, { query: {} })
}
export function useReportSchedaHtml(id: string) {
  return useData<string>(`${API_URL}/report-scheda/${id}/html-rt`, {
    query: { include_bootstrap: "false" },
    responseType: "text",
  })
}
export function useReportSchedaPdf(id: string) {
  return useAction<Blob, void>(`${API_URL}/report-scheda/${id}/pdf-rt`, { method: "GET", responseType: "blob" })
}
export function useReportSchedaExcel(id: string) {
  return useAction<Blob, void>(`${API_URL}/report-scheda/${id}/excel-rt`, { method: "GET", responseType: "blob" })
}
