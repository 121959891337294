import { saveAs } from "file-saver"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { Fragment, useCallback, useMemo, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { MdDownload } from "react-icons/md"
import { useMatch, useNavigate } from "react-router-dom"
import MyDatePicker from "../../components/MyDataPicker"
import Paginator from "../../components/Paginator"
import SchedaSelector from "../../components/SchedaSelector"
import StandardLayout from "../../components/StandardLayout"
import ViaSelector from "../../components/ViaSelector"
import { useOverviewDetail, useOverviewTabella, useVieList } from "../../components/ViaSelector/vie"
import { API_URL } from "../../constants"
import { encodeQS, mkStringfromDate, removeEmpties } from "../../helper"
import { useCatalogoServizio } from "../../hooks/catalogoservizi"
import { useQsFilters } from "../../hooks/useFilters"
import { DettaglioPDS, NonEseguito, Scheda } from "../../types"
import { getServizioLink } from "../NonEseguito/NonEseguito"
import { OverviewMap } from "./OverviewMap"

const initFilters = (params: URLSearchParams) => ({
  tab: params.get("tab") ?? ("table" as "table" | "map"),
  filterVia: params.get("filterVia") ?? "",
  selectVia: params.get("selectVia") ?? "",
  page: Number(params.get("page") ?? 1),
})

export default function Overview() {
  const match = useMatch("/overview/:type/:schedaId/:data/*")
  const type = match?.params.type! as "non-eseguito" | "pianificato"
  const date = match?.params.data!
  const schedaId = match?.params.schedaId!
  const [features, setFeatures] = useState<Blob>()

  const { filters, setFilters } = useQsFilters(initFilters)

  const navigate = useNavigate()

  const selectedVieFilters = useMemo(() => {
    return removeEmpties({
      streetcode: filters.selectVia,
    })
  }, [filters.selectVia])

  const { data: selectedVie } = useVieList(selectedVieFilters)
  const selectedVia = selectedVie?.results?.find((v) => v.streetcode.toString() === filters.selectVia)

  const [modalTableDetail, modalActionsTableDetail] = useModalTrigger()

  const { data: schedaServizio } = useCatalogoServizio(schedaId)
  const { data: overview } = useOverviewTabella(
    type,
    schedaId,
    date,
    removeEmpties({
      page: String(filters.page),
      streetcode: filters.filterVia,
    })
  )

  const downloadFeatures = useCallback(() => {
    if (features !== null && features !== undefined) {
      saveAs(features, `Features_${type}_${schedaId}_${date}.geojson`)
    }
  }, [features])

  const { data: overviewDetail, isLoading } = useOverviewDetail(type, schedaId, date, filters.selectVia)

  return (
    <StandardLayout classNameContent="d-flex flex-column align-items-between overflow-hidden pb-0">
      <div className="shadow-sm p-3 bg-light" style={{ zIndex: 1 }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-item-center">
            <div className="page-title">{`${schedaServizio?.codice_scheda} ${schedaServizio?.descrizione}`}</div>
            {selectedVia && filters.tab === "map" && (
              <div className="page-title ps-2">
                {" / "}
                <span className="text-primary">
                  {selectedVia.streetcode} - {selectedVia?.toponym}
                </span>
              </div>
            )}
          </div>
          {filters.tab === "table" ? (
            <Button size="sm" onClick={() => setFilters({ ...filters, tab: "map" })}>
              Mappa
            </Button>
          ) : (
            <div className="d-flex gap-2">
              <Button variant="light" size="sm" onClick={() => downloadFeatures()}>
                <MdDownload />
              </Button>
              <Button size="sm" onClick={() => setFilters({ ...filters, tab: "table" })}>
                Tabella
              </Button>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-start align-item-center py-1">
          <Button
            className={`nav-item pointer me-1 btn-sm  ${type === "pianificato" ? "btn-primary" : "btn-light"}`}
            onClick={() => {
              const qs = encodeQS(filters)
              navigate(`/overview/pianificato/${schedaId}/${date}/?${qs.toString()}`)
            }}
          >
            {"Pianificato"}
          </Button>
          <Button
            className={`nav-item pointer me-1 btn-sm  ${type === "non-eseguito" ? "btn-primary" : "btn-light"}`}
            onClick={() => {
              const qs = encodeQS(filters)
              navigate(`/overview/non-eseguito/${schedaId}/${date}/?${qs.toString()}`)
            }}
          >
            {"Non Eseguito"}
          </Button>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div className="col mx-2 p-2 pb-0">
            <MyDatePicker
              selected={date}
              onChange={(date) => {
                const finalDate = mkStringfromDate(date, "YYYY-MM-DD")
                const qs = encodeQS({ ...filters, page: 1 })
                if (date) navigate(`/overview/${type}/${schedaId}/${finalDate}/?${qs.toString()}`)
              }}
            />
          </div>

          <div className="col mx-2 p-2 pb-0">
            <SchedaSelector
              onChange={(v: Scheda[]) => {
                const qs = encodeQS({ ...filters, page: 1 })
                if (v.length === 1) navigate(`/overview/${type}/${v[0].id.toString()}/${date}/?${qs.toString()}`)
                else navigate(`/overview/${type}/0/${date}/?${qs.toString()}`)
              }}
              currentFilter={schedaId}
            />
          </div>

          <div className="col mx-2 p-2 pb-0">
            <ViaSelector
              currentFilter={filters.filterVia || undefined}
              onChange={(v) => {
                if (v.length === 1) {
                  setFilters({
                    ...filters,
                    filterVia: v[0].streetcode.toString(),
                    selectVia: v[0].streetcode.toString(),
                  })
                } else {
                  setFilters({ ...filters, filterVia: "", selectVia: "" })
                }
              }}
            />
          </div>
        </div>
      </div>

      {filters.tab === "table" && overview && (
        <>
          <div className="flex-1 position-relative">
            <div
              className="p-3 pb-0"
              style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, overflow: "scroll" }}
            >
              <table className="table table-hover table-bordered table-hover">
                <thead>
                  <tr className="table-info">
                    <th>Toponimo</th>
                    <th>Streetcode</th>
                    <th>Quantità</th>
                  </tr>
                </thead>
                <tbody>
                  {overview.results.map((overview) => (
                    <Fragment key={overview.id}>
                      <tr
                        key={overview.id}
                        onClick={() => {
                          setFilters({ ...filters, selectVia: overview.streetcode.toString() })
                          modalActionsTableDetail.open("dummy")
                        }}
                      >
                        <td>{overview.toponym}</td>
                        <td>{overview.streetcode}</td>
                        <td>{overview.quantita}</td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="bg-light border-top p-3">
            <Paginator
              count={overview?.count ?? 0}
              currentPage={filters.page}
              goToPage={(page) => setFilters({ ...filters, page })}
            />
          </div>
        </>
      )}
      {filters.tab === "map" && (
        <>
          <div className={"w-100 p-3 pb-4"} style={{ flex: "0 0 60%" }}>
            <div className="w-100 h-100 position-relative">
              <OverviewMap
                url={`${API_URL}/vie/geo-${type}/${schedaId}/${date}/${
                  filters.filterVia ? `?streetcode=${filters.filterVia}` : ""
                }`}
                selectedVia={parseInt(filters.selectVia, 10)}
                onClick={(feature) => {
                  setFilters({ ...filters, selectVia: feature.getProperties().streetcode.toString() })
                }}
                setFeatures={setFeatures}
              />
            </div>
          </div>
          {overviewDetail && overviewDetail.length > 0 && type === "pianificato" && (
            <div className="flex-1 position-relative">
              <div
                className="px-3"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: "scroll",
                }}
              >
                <TableDettaglioPDS dettagliPDS={overviewDetail as DettaglioPDS[]} date={date} />
              </div>
            </div>
          )}
          {overviewDetail && overviewDetail.length > 0 && type === "non-eseguito" && (
            <div className="flex-1 position-relative ">
              <div
                className="px-3"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: "scroll",
                }}
              >
                <TableDettaglioVNE nonEseguito={overviewDetail as NonEseguito[]} via={selectedVia?.toponym} />
              </div>
            </div>
          )}
          {(!overviewDetail || overviewDetail.length === 0) && !isLoading && selectedVia && (
            <div className="px-3 flex-1">
              <div className="py-1">{`Non sono presenti dati`}</div>
            </div>
          )}
        </>
      )}
      {modalTableDetail.value && (
        <Modal
          show={modalTableDetail.isOpen}
          onHide={modalActionsTableDetail.toggle}
          contentClassName="bg-topbar"
          centered
          size={overviewDetail && overviewDetail?.length > 0 ? "xl" : "sm"}
          onExited={() => {
            if (!filters.filterVia) {
              setFilters({ ...filters, selectVia: "" })
            }
          }}
          scrollable
        >
          <Modal.Header>
            <Modal.Title>{`${selectedVia?.streetcode} - ${selectedVia?.toponym}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            {type === "non-eseguito" && overviewDetail && overviewDetail?.length > 0 && (
              <>
                <div className="sticky-padding-cover" />
                <TableDettaglioVNE nonEseguito={overviewDetail as NonEseguito[]} via={selectedVia?.toponym} />
              </>
            )}
            {type === "pianificato" && overviewDetail && overviewDetail?.length > 0 && (
              <>
                <div className="sticky-padding-cover" />
                <TableDettaglioPDS dettagliPDS={overviewDetail as DettaglioPDS[]} date={date} />
              </>
            )}
            {overviewDetail && overviewDetail?.length === 0 && <div className="py-1 ">{`Non sono presenti dati`}</div>}
          </Modal.Body>
        </Modal>
      )}
    </StandardLayout>
  )
}

type TableDettaglioPDSProps = {
  dettagliPDS: DettaglioPDS[]
  date: string
}

function TableDettaglioPDS({ dettagliPDS, date }: TableDettaglioPDSProps) {
  return (
    <table
      className="table table-hover table-sticky-header align-middle"
      // style={{ borderCollapse: "separate", borderSpacing: 0 }}
    >
      <thead>
        <tr className="table-info align-top">
          <th>Codice Viario</th>
          <th>Loc ref</th>
          <th>Unità territoriale</th>
          <th>Descr. elemento</th>
          <th>Data</th>
          <th>Giorno</th>
          <th>Ora inizio</th>
          <th>Ora fine</th>
          <th>Quantità</th>
          <th>Squadra</th>
          <th>Lato</th>
          <th>Servizio gestore</th>
        </tr>
      </thead>
      <tbody>
        {dettagliPDS.map((item) => {
          return (
            <tr key={item.piano_servizi}>
              <td>{item.streetcode}</td>
              <td>{item.loc_ref}</td>
              <td>{item.unita_territoriale}</td>
              <td>{item.descrizione_elemento}</td>
              <td>{date}</td>
              <td>{item.tipo_giorno}</td>
              <td>{item.ora_inizio}</td>
              <td>{item.ora_fine}</td>
              <td>{item.quantita}</td>
              <td>{item.squadra}</td>
              <td>{item.lato}</td>
              <td>{item.servizio_gestore}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

type TableDettaglioVNEProps = {
  nonEseguito: NonEseguito[]
  via: string | undefined
}
function TableDettaglioVNE({ nonEseguito, via }: TableDettaglioVNEProps) {
  return (
    <table className="table table-hover table-sticky-header align-middle">
      <thead>
        <tr className="table-info align-top">
          <th className="sticky-top">ID</th>
          <th className="sticky-top">Servizio</th>
          <th className="sticky-top">Data</th>
          <th className="sticky-top">Nome via</th>
          <th className="sticky-top">Streetcode</th>
          <th className="sticky-top">Loc_ref</th>
          <th className="sticky-top">Unità territoriale</th>
          <th className="sticky-top">Descr. elemento</th>
          <th>Lato</th>
          <th className="sticky-top">Causale</th>
          <th className="sticky-top">Esecuzione</th>
          <th className="sticky-top">Minuti ritardo</th>
          <th className="sticky-top">Quantità</th>
        </tr>
      </thead>
      <tbody>
        {nonEseguito.map((item) => {
          return (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{getServizioLink(item)}</td>
              <td>{mkStringfromDate(item.data, "DD/MM/YYYY")}</td>
              <td>{via}</td>
              <td>{item.streetcode}</td>
              <td>{item.loc_ref}</td>
              <td>{item.unita_territoriale}</td>
              <td>{item.descrizione_elemento}</td>
              <td>{item.lato}</td>
              <td>{item.causale}</td>
              <td>{mkStringfromDate(item.dataora_esecuzione, "DD/MM/YYYY HH:mm")}</td>
              <td>{item.minuti_ritardo}</td>
              <td>{item.quantita}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
